#root > header {
	background: color(corporate-yellow);
	box-shadow: 0 5px 6px rgba(0,0,0,0.5);
	padding: 6rem 2rem 1rem;

	.logo {
		img {
			max-width: 450px;
			height: auto;
		}
	}

	@include tablet {
		padding-top: 2rem;
	}

	@include phone {
		padding-left: $side-space-small;
		padding-right: $side-space-small;
	}
}