.alert {
	background: white;
	max-width: $content-width;
	width: 95vw;
	padding: 1rem 2rem;
	border: solid 1px;

	&.error {
		background: $error;
		color: darken($error, 55);
		border-color: darken($error, 10);
	}

	&.warning {
		background: $warning;
		color: darken($warning, 55);
		border-color: darken($warning, 10);
	}

	&.info {
		background: $info;
		color: darken($info, 55);
		border-color: darken($info, 10);
	}

	&.success {
		background: $success;
		color: darken($success, 55);
		border-color: darken($success, 10);
	}

	button {
		padding: 0.5rem;
		border-color: inherit;
		color: inherit;

		.avisicon {
			margin: 0;
		}
	}

	.grid {
		> span {
			align-self: center;

			&:last-child {
				font-size: 0.9rem;
			}
		}
	}

	.avisicon.alert-icon {
		font-size: 2rem;
		vertical-align: middle;
	}
}