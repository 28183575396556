// COLORS
$colors : (
	corporate-yellow : #FCBB01,
	corporate-blue   : #0352A1,
	background       : white,
	light-grey-bg	 : #F6F7F8,
	light-black		 : #404145,
	light-fg         : white,
	text-lighter     : #525556,
	border-light     : #808080,
	form-error		 : #A80101,
);


$error   : #f8d7da;
$warning : #fff3cd;
$info    : #cff4fc;
$success : #badbcc;

:root {
	@each $colorName, $colorValue in $colors {
		--color-#{"" + $colorName} : #{$colorValue};
	}
}

// VARIABLES
$mobile-width      : 960px;
$content-width     : 1440px;
$tablet 		   : 768px;
$phone  		   : 450px;
$phone-medium	   : 375px;
$phone-small	   : 300px;
$side-space-small  : 15px;
$font-path         : "/fonts";
$icomoon-font-path : "#{$font-path}/avisicons" !default;