.screen {
	padding-top: 4rem;
	background-color: color(light-grey-bg);

	@include tablet {
		padding-top: 2rem;
	}

	@include phone {
		padding-top: 1.5rem;
	}

	&.parcel-not-found {
		>.wrapper {
			margin-bottom: 4rem;

			@include tablet {
				margin-bottom: 2rem;
			}
		
			@include phone {
				margin-bottom: 1.5rem;
			}
			
			h1 {
				margin: 0;
			}
		}
	}

	h2.notice {
		img {
			max-width: 200px;
			height: auto;

			@include tablet {
				max-width: 150px;
			}

			@include phone-small {
				max-width: 80px;
			}
		}
	}
	
	p {
		margin: 2rem 0;

		&:first-of-type {
			margin-top: 1rem;
		}

		@include tablet {
			margin-top: 1rem;
		}
	}

	&.main {

		.zip-input, .section-yellow-bg {
			background: color(corporate-yellow);
			padding: 2rem 0 6rem;
		}

		.address-field {
			width: 50%;
			margin: 4rem 0;

			address:first-of-type {
				border-right: solid 2px #eee;
			}
		}

		.wrapper {
			padding: 0 2rem;

			@include phone {
				padding-left: $side-space-small !important;
				padding-right: $side-space-small !important;
			}
		}
	}

	&.not-found {
		padding: 2rem;

		@include phone {
			padding-left: $side-space-small !important;
			padding-right: $side-space-small !important;
		}
	}

	&.modify-order {
		table {
			margin: 1rem 0 2rem;
			border-spacing: 0;

			td {
				padding: 0;

				&:first-of-type {
					padding-right: 10px;
				}
			}
		}

		.wrapper {
			padding: 0 2rem;

			@include phone {
				padding-left: $side-space-small !important;
				padding-right: $side-space-small !important;
			}
		}

		section.drop-off-premission {
			.wrapper {
				padding: 0 !important;
				grid-column: 2 / -1;
			}

			@include content-width {
				grid-template-columns: 1fr !important;
				max-width: 70%;

				.wrapper {
					grid-column: unset;
				}
			}

			@include tablet {
				max-width: 100%;
			}

			@include phone {
				.wrapper {
					padding: 0 !important;
				}
			}
		}

		.order-details {
			margin-bottom: 4rem;
		}

		.modify-delivery, .check-contact-data-section {
			background: color(corporate-yellow);
			padding: 2rem 0 6rem;
		}

		.check-contact-data {
			background-color: color(light-grey-bg);
			margin-bottom: 2rem;
			padding-bottom: 2rem;
			
			
			.check-contact-data-header {
				color: color(text-lighter);
				
				i {
					color: color(corporate-blue);
					font-size: 3rem;
			 	}

				.first {
					gap: 0.5rem;
					display: flex;
					align-items: center;
					text-transform: uppercase;
				}

				@include content-width {
					grid-template-columns: 1fr !important;

					.second {
						p {
							margin-top: 0;
						}
					}
				}
			}

			.check-contact-data-content {
				@include content-width {
					grid-template-columns: 1fr !important;
				}
			}

			> .wrapper {
				@include tablet {
					padding-top: 20px;
				}
			}
		}

		.modal-parcel-details {
			h2 {
				padding: 0;
				margin: 0 0 4rem 0;

				@include phone {
					margin-bottom: 2rem;
				}
			}

			.grid {
				grid-gap: 0;

				table {
					width: 100%;

					tr {
						td:first-child {
							white-space: nowrap;
						}

						td:last-child {
							line-height: normal;
							word-wrap: break-word;
						}
					}
				}

				> * {
					padding: 2rem;
					border-right: solid 2px color(light-grey-bg);

					@include phone {
						border-right: none;
						border-bottom: solid 2px color(light-grey-bg);
					}
				}

				article {
					padding: 0;
				}
			}
		}
	}
}