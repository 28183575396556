input[type=submit], input[type=button], button {
	background: color(corporate-blue);
	color: color(light-fg);
	border: none;
	padding: 1rem 1.7rem;
	font-size: .9rem;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&.outline {
		background: transparent;
		color: color(text-lighter);
		border: solid 1px color(border-light);

		&:active {
			background: rgba(0,0,0,0.1);
		}
	}

	&.thin {
		padding: 0.5rem 4rem;
	}

	&.plaintext {
		text-transform: none;
	}

	.avisicon {
		vertical-align: middle;
	}
}

input[type=text], input[type=email], input[type=tel] {
	padding: 0.8rem;
	border: solid 1px color(border-light);
	border-radius: 4px;
	font-size: 16px !important;

	&.pickup-person {
		width: 90%;

		@include mobile {
			max-width: 300px;
		}
	}

	&[class*=react-inputs-validation__error] {
		border: solid 2px color(form-error);
	}
	
	@include phone {
		width: 100%;
	}
}

textarea {
	padding: 0.8rem;
	border: solid 1px color(border-light);
	border-radius: 4px;
	font-size: 16px !important;
	font-family: inherit;

	&[class*=react-inputs-validation__error] {
		margin-bottom: 0 !important;
	}
}

.zip-input {
	input {
		width: 310px;
		margin-bottom: 1rem;

		@include phone-medium {
			width: 100%;
		}
	}
}

label {
	&.label-above-input {
		display: block;
		margin-bottom: .3em;
	}
}

.drop-off-location-form {
	textarea {
		width: 100%;
		margin-bottom: 1rem;
	}

	button.blue-button {
		margin-top: 2rem;
		margin-left: auto;
		display: block;

		@include phone {
			margin-left: 0;
		}
	}

	.choose-location {
		>p {
			margin-bottom: .7em;
		}
	}

	.drop-off-location-radiobox {
		>div {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 1rem 2rem;
			gap: 1rem 2rem;
			max-width: 80%;
			margin-bottom: 1rem;

			button {
				align-self: unset !important;
				width: max-content;

				@include phone-medium {
					width: max-content !important;
					max-width: max-content !important;
				}
			}

			@include tablet {
				max-width: 100%;
			
				@include phone {
					grid-template-columns: 1fr 1fr;
				}
			}



			&[class*=react-inputs-validation__error] {
				margin-bottom: 0;
			}
		}

		[class*=react-inputs-validation__msg] {
			display: block;
		}
	}
}

.check-contact-data-form {
	grid-column: 2 / -1;

	.name-input {
		margin-bottom: 2.2rem;
	}

	p {
		margin-bottom: 1em;
		color: color(light-black);
	}

	label {
		&.label-above-input {
			font-size: .8rem;
			color: color(light-black);
		}
	}

	input {
		&[type=text], &[type=email], &[type=tel] {
			width: 300px;
			color: color(border-light);

			@include phone {
				width: 100%;
			}
		}
	}

	button {
		margin-top: 1rem;
	}

	.notification-method-wrapper {
		margin-bottom: 20px;

		p:last-of-type {
			margin-top: 1rem;
			margin-bottom: 2rem;
		}
	}

	@include content-width {
		grid-column: unset;
	}
}

input[type=checkbox], input[type=radio] {
	margin-right: 10px;
	margin-left: 0;
}

button {
	&[class*=react-inputs-validation__button], &.checkbox-wrapper {
		background: none;
    	color: inherit;
    	border: none;
    	padding: 0;
    	font-size: 0.9rem;
    	text-transform: unset;
    	cursor: pointer;
    	transition: all 0.3s ease-in-out;

		label {
			font-size: 1rem;
			font-family: "Rubik", sans-serif;
			line-height: 1.8rem;
		}
	}
}


[class*=react-inputs-validation__error] {
	input[type=text], input[type=email], input[type=tel] {
		border: solid 2px color(form-error) !important;
	}

}

[class*=react-inputs-validation__msg] {
	color: color(form-error);
}

div.checkbox-container {
	position: relative;
	margin-bottom: 2rem;

	@media (max-width: 500px) {
		margin-bottom: 4rem;
	}

	button {
		@include phone-medium {
			width: max-content !important;
			max-width: max-content !important;
		}
	}
	
	.checkbox-wrapper {
		
		[class*=react-inputs-validation__msg] {
			position: absolute;
			left: 0;
			top: 100%;	
			font-size: 1rem;
			font-family: "Rubik", sans-serif;
			line-height: 1.8rem;
			text-align: left;
		}
	
	}
}

p.error {
	margin-top: 0 !important;
	margin-bottom: 1rem;
	color: color(form-error);
}

.decline-reasons-radiobox {
	> div {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 1rem 2rem;
		gap: 1rem 2rem;
		margin-bottom: 1rem;

		button {
			align-self: unset !important;
			width: max-content;
		}
	}
}