h1, h2, h3, h4 {
	color: color(corporate-blue);
	font-weight: normal;
	line-height: normal;

	&.notice {
		text-transform: uppercase;
	}
}

h1 {
	font-size: 2.3rem;
}

h2 {
	font-size: 2.3rem;
}

h3 {
	font-size: 1.5rem;

	&.icon-headline {
		display: flex;
		flex-flow: row nowrap;

		i {
			font-size: 3rem;
			margin-right: .3em;
			vertical-align: middle;
		}
	}
}

strong {
	color: color(corporate-blue);
	text-transform: uppercase;
	font-weight: normal;
}

.heading {
	color: color(corporate-blue);
	font-weight: normal;
	font-size: 1.4rem;
}

@include mobile {
	h1, h2 {
		font-size: 2rem;
	}
	h3 {
		font-size: 1.3rem;
	}
}

@include phone {
	h1, h2 {
		font-size: 1.7rem;
		margin-bottom: 1.5em;
	}
	.agb-content {
		h1, h2 {
			font-size: 1.3rem;
		}
	}
	h1, h2, h3, h4 {
		hyphens: auto;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		max-width: 100%;
	}
}