@import "variables";
@import "mixins";
@import "fonts";

@import "grids";
@import "forms";
@import "headings";
@import "buttons-and-links";
@import "alert";

@import "header";
@import "footer";
@import "navigations";
@import "screens";
@import "accordion";
@import "modal";
@import "boxes";
@import "sections";

@import "overrides";

* {
	box-sizing: border-box;
}

html, body {
	font-size: 18px;

	@include mobile {
		font-size: 16px;
	}
}

body {
	margin: 0;
	padding: 0;
	font-family: "Rubik", sans-serif;
	line-height: 1.8rem;
}

.wrapper {
	max-width: $content-width;
	margin: 0 auto;
}

p {
	b, strong {
		font-weight: 600;
	}
}

.loader {
	@include loader(color(corporate-blue));
}