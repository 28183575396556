nav {
	&.footer {
		position: absolute;
		bottom: 0;
		right: 0;

		a {
			&:not(:first-child) {
				margin-left: 2rem;
			}
		}

		@include tablet {
			position: relative;
			bottom: unset;
			right: unset;
			display: flex;
			flex-flow: row wrap;

			a {
				&:not(:first-child) {
					margin-left: 0;
				}

				&:not(:last-of-type) {
					margin-right: 1rem;
				}
			}
		}
	}
}