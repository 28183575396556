#root > footer {
	padding: 4rem 2rem;
	color: color(text-lighter);
	line-height: 1.5rem;

	.wrapper {
		position: relative;
	}

	a {
		color: color(text-lighter);
	}

	@include phone {
		padding: 1.5rem $side-space-small;
	}
}