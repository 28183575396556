.option-confirmation-box {
    background-color: #fff;
    display: flex;
    flex-flow: row wrap;
    padding: 25px;
    margin-bottom: 5rem;

    i {
        display: block;
        color: color(corporate-blue);
        margin-right: 30px;
        margin-bottom: 1rem;
        font-size: 3rem;
    }

    div {
        p {
            color: color(corporate-blue);
            font-size: 1.2rem;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            b, strong {
                font-weight: 600;
                text-transform: none;
            }
        }
    }
}