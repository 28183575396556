.modal {
	position: fixed;
	display: grid;
	place-content: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
	background: transparentize(map-get($colors, corporate-blue), 0.05);
	z-index: 5;

	main.content {
		background: white;
		max-width: $content-width;
		max-height: 80vh;
		overflow: auto;
		width: 95vw;
		padding: 2rem;
		position: relative;

		>button {
			position: sticky;
			top: 0;
			background-color: #fff;
		}

		h2 {
			&:first-of-type {
				margin-top: 0;
			}
		}

		.accept-agb-wrapper {
			position: sticky;
			bottom: -2rem;
			background-color: rgba(255, 255, 255, .7);
			width: 100%;
			padding: 20px 0;
		}

		@include tablet {
			h2 {
				clear: both;
				padding-top: 15px !important;
			}
		}

		@include phone {
			padding: 2rem $side-space-small;
			max-height: 85vh;
		}
	}
}