section {
    &.confirmation-message-section {
        background-color: color(corporate-yellow);
        padding: 2rem;

        .wrapper {
            padding: 0 !important;
        }
        b, strong {
            font-weight: 600;
            word-break: keep-all;
            hyphens: none !important;
        }

        @include phone {
            padding: 2rem $side-space-small;

            .wrapper {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
}

@include phone {
    .screen.modify-order .confirmation-message-section .wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
