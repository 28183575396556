@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?i1zk9f');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?i1zk9f#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?i1zk9f') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?i1zk9f') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?i1zk9f##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="avisicon-"], [class*=" avisicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.avisicon-alert-warn_error {
  &:before {
    content: $avisicon-alert-warn_error; 
  }
}
.avisicon-alert-success {
  &:before {
    content: $avisicon-alert-success; 
  }
}
.avisicon-alert-info {
  &:before {
    content: $avisicon-alert-info; 
  }
}
.avisicon-user {
  &:before {
    content: $avisicon-user; 
  }
}
.avisicon-abort {
  &:before {
    content: $avisicon-abort; 
  }
}
.avisicon-arrow-right {
  &:before {
    content: $avisicon-arrow-right; 
  }
}
.avisicon-refuse-delivery {
  &:before {
    content: $avisicon-refuse-delivery; 
  }
}
.avisicon-check {
  &:before {
    content: $avisicon-check; 
  }
}
.avisicon-pin {
  &:before {
    content: $avisicon-pin; 
  }
}
.avisicon-self-pickup {
  &:before {
    content: $avisicon-self-pickup; 
  }
}
.avisicon-delivery-date {
  &:before {
    content: $avisicon-delivery-date; 
  }
}

