.grid {
	display: grid;
	gap: 1rem 2rem;

	&.half-half {
		grid-template-columns: 1fr 1fr;	
	}
	
	&.three-thirds {
		grid-template-columns: 1fr 1fr 1fr;

		@include tablet {
			grid-template-columns: 1fr 1fr !important;
		}
		@include phone {
			grid-template-columns: 1fr !important;
		}
	}
	
	&.thirty-seventy {
		grid-template-columns: 1fr 2fr;
	}
	
	&.seventy-thirty {
		grid-template-columns: 2fr 1fr;
	}

	&.ten-ninety {
		grid-template-columns: 1fr 11fr;
	}
	
	@include phone {
		grid-template-columns: 1fr !important;
	}
}