$icomoon-font-family: "RM-WebAvis-Icons" !default;
$icomoon-font-path: "fonts" !default;

$avisicon-alert-warn_error: "\e908";
$avisicon-alert-success: "\e909";
$avisicon-alert-info: "\e90a";
$avisicon-user: "\e907";
$avisicon-abort: "\e900";
$avisicon-arrow-right: "\e901";
$avisicon-refuse-delivery: "\e902";
$avisicon-check: "\e903";
$avisicon-pin: "\e904";
$avisicon-self-pickup: "\e905";
$avisicon-delivery-date: "\e906";