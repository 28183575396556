@mixin content-width {
	@media (max-width: $content-width) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $mobile-width) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: $tablet) {
		@content;
	}
}

@mixin phone {
	@media (max-width: $phone) {
		@content;
	}
}

@mixin phone-medium {
	@media (max-width: $phone-medium) {
		@content;
	}
}

@mixin phone-small {
	@media (max-width: $phone-small) {
		@content;
	}
}

@function color($color) {
	@return var(--color-#{$color});
}

@mixin loader($loader-color) {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	  
	div {
		display: inline-block;
		position: absolute;
		left: 8px;
		width: 16px;
		background: $loader-color;
		animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

		&:nth-child(1) {
			left: 8px;
			animation-delay: -0.24s;
		}

		&:nth-child(2) {
			left: 32px;
			animation-delay: -0.12s;
		}

		&:nth-child(3) {
			left: 56px;
			animation-delay: 0;
		}
	}

	@keyframes loader {
		0% {
		  top: 8px;
		  height: 64px;
		}
		50%, 100% {
		  top: 24px;
		  height: 32px;
		}
	}
	  
}