@import "lib/avisicons/style.scss";

/**
 * Font family "Rubik"
 *
 * @author Michael Ochmann <michael.ochmann@propeller.de>
 * @license Open Font License
 */

// normal
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-Regular.ttf");
}
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-Italic.ttf");
	font-style: italic;
}

// bold
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-Bold.ttf");
	font-weight: bold;
}
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-BoldItalic.ttf");
	font-weight: bold;
	font-style: italic;
}

// lighter
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-LightItalic.ttf");
	font-weight: 300;
	font-style: italic;
}

// medium
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-Medium.ttf");
	font-weight: 600;
}
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-MediumItalic.ttf");
	font-weight: 600;
	font-style: italic;
}

// bolder
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-Black.ttf");
	font-weight: 800;
}
@font-face {
	font-family: "Rubik";
	src: url("#{$font-path}/rubik/Rubik-BlackItalic.ttf");
	font-weight: 800;
	font-style: italic;
}