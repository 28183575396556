.white-button {
    background: #fff;
	color: #000;
	border: 2px solid color(border-light);
	padding: .8rem 1.2rem;
	text-transform: none;
	font-family: "Rubrik", sans-serif;
	font-size: 1rem;
	height: max-content;
	width: max-content;
	max-width: 100%;

	&.confirm-drop-off-location, &.confirm-self-pickup, &.confirm-refuse-delivery {
		margin-top: .3rem;
	}

	&:hover {
		cursor: pointer;
	}
}

.blue-button {
    @extend .white-button;
	background: color(corporate-blue);
	color: #fff;
	border: none;

	&.confirm-delivery-date {
		margin-top: .3rem;
	}
}

a {
	text-decoration: none;
	color: color(corporate-blue);
}

.date-suggestion {
	button.blue-button {
		margin-top: 2rem;
	}
}

button {
	&.disabled {
		opacity: .7;

		&:hover {
			cursor: not-allowed;
		}
	}
}