$transisitontime: 1.2s;

.accordion {
    padding-right: 4rem;
    height: auto;
    min-height: 200px;
    background-color: color(light-grey-bg);
    margin-bottom: 2rem;

    &.open {
        padding-bottom: 2rem;
    }

    

    textarea {
        width: 100%;
        height: 150px;
        resize: none;
    }

    @include content-width {
        padding-right: 0;
    }

    @include phone-medium {
        button {
            max-width: 100% !important;
            width: 100% !important;
        }
    }

   .optionHeader {
       width: 100%;
       min-height: 200px;
       display: flex; 
       align-items: flex-start;
       justify-content: space-between;
       padding: 2.5rem 0 2rem;
       gap: 2rem;
       color: color(light-black);
       
       i {
           color: color(corporate-blue);
           font-size: 3rem;
        }

        & > div {
            flex: 1 1 0px;

            &:first-of-type {
                gap: 0.5rem;
                display: flex;
                align-items: center;
                text-transform: uppercase;
            }
        }

        p {
            &:first-of-type {
                margin-top: .3rem;
            }
        }

        h3 {
            margin: 0;
        }

        .second {
            button {
                width: 300px;
            }

        }

        .third {
            button {
                width: 100%;
            }
            @include content-width {
                button {
                    max-width: 300px;
                }
            }
        }

        @include content-width {
            display: grid;
            grid-template-columns: 1fr;
            padding-bottom: 20px;
            max-width: 70%;

            .third {
                button {
                    margin: 0;
                }
            }
        }

        @include tablet {
            max-width: 100%;
            padding-top: 20px;

            button {
                width: 100%;
            }
        }
   }

    .innerContent {
        overflow: hidden;
        max-height: 1000px;
        height: auto;
        transition: max-height $transisitontime ease-in-out;

        @include phone {
            max-height: unset;
        }

        &.hidden {
            max-height: 0px;
            height: 0;
        }

        .alternative-delivery-dates {
            >p:last-of-type {
                max-width: 60%;

                @include mobile {
                    max-width: 100%;
                }
            }
        }
        .date-suggestion {
            padding: 2rem 0;
            color: color(light-black);
            border-top: 1px solid color(border-light);
            
            &:last-of-type {
                border-bottom: 1px solid color(border-light);
            }

            >div p {
                display: flex;
                flex-flow: row wrap;
                align-items: center;

                i {
                    font-size: 1.6rem;
                    margin-right: 10px;
                }
            }

            p {
                margin: 0;
            }
            button {
                margin: 0;
                width: 100%;
            }

            @media (max-width: 1060px) {
                grid-template-columns: 1fr !important;
                grid-gap: 20px;
                gap: 20px;
                padding: 20px 0;

                button {
                    max-width: 300px;
                }

                
            }
        }

        .col-one-right {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2em;

            & > div {
                grid-column: 2 / span 2;
                display: flex;
                flex-direction: column;
                padding-right: 6em;
            }

            button {
                align-self: flex-end;
            }

            @include content-width {
                grid-template-columns: 1fr 1fr;

                & > div {
                    grid-column: unset;
                    padding-right: 0;
                }

                button {
                    max-width: 300px;
                }
            }

            @include tablet {
                grid-template-columns: 1fr;
            }
        }

        .col-three {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2em;
            > div { 
                flex: 1 1 0px;

                &:last-of-type {
                    align-self: flex-end;

                    @include content-width {
                        button {
                            max-width: 300px;
                        }
                    }
                }

                &:nth-of-type(3) {
                    button {
                        width: 100%;
                    }
                }

                &:first-of-type {
                    max-width: 80%;

                    @include mobile {
                        max-width: 100%;
                    }
                }
            }

            input[type=text] {
                border: 1px solid lightgray;
                border-radius: 0;
                outline: none;

                @include tablet {
                    width: 100%;
                }
            }

            @include mobile {
                grid-template-columns: 1fr;
            }
        }

        .drop-off-premission {
            .wrapper {
                >p {
                    margin-top: 0;
                }

                /* .choose-location {
                    @include content-width {
                        button {
                            max-width: 300px;
                        }
                    }
                    
                } */
            }
        }
    }
}